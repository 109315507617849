import React, { Component } from "react";

import 'intersection-observer'

import { InView } from 'react-intersection-observer';


export default class Clients extends Component {
	constructor(props){
		super(props);
		this.isLoaded = false
		this.state = {
			in_view: false,
			position: null
		};
		this.render_clients = this.render_clients.bind(this);
		this.handleScroll = this.handleScroll.bind(this)
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		this.handleScroll()
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
	handleScroll() {
		if (this.parent.getBoundingClientRect().top < window.innerHeight && !this.isLoaded) {
			this.isLoaded = true
			const images = this.parent.querySelectorAll('img[data-src]')
			for (const image of images) {
				image.src = image.dataset.src
			}
		}
	}
	handleInView(inView) {
		if (inView) {
			this.title.classList.remove("invisible");
		}
	}
	render_clients(){
		if(this.props.data.clients_feature){
			return this.props.data.clients_feature.map((client) =>
				<div key={ client.name } className="grid-m-3 grid-t-1-5 grid-d-1-5 client">
					<img data-src={client.logo} alt={client.name} src={client.logo} loading="lazy"/>
				</div>
			);
		}
	}
	render() {
		return(
			<InView as="div" triggerOnce={true} rootMargin="0% 0% -10%" className="grid clients-feature" onChange={inView => this.handleInView(inView)}>
				<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1" ref={c => this.parent = c}>
					<div className="grid-reset">
						<h2 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow default-animation invisible" ref={c => this.title = c}>Our Clients</h2>
						<div className="row client-wrap">
							{this.render_clients()}
						</div>
					</div>
				</div>
			</InView>
		);
	}
}
