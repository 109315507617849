import React, { Component } from 'react';
import { Link } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import Footer from "../components/Footer/Footer.js";
import EnterHeader from "../components/repeating_elements/Enter_Header.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";

import SEO from '../components/seo.js';
import PanoramicHero from "../components/Global/Panoramic_Hero.js";
import MissionStatement from "../components/Longform/Mission_Statement.js";
import Clients from "../components/Longform/Clients.js";
import Contact from "../components/Longform/Contact.js";
import Accordion from "../components/Longform/Accordion.js";
import PageBreak from "../components/repeating_elements/page_break.js";
import { ArrowLink } from "../components/buttons/Arrow_Link.js";
import { HeaderTwo, BodyCopy, HeaderEyebrow } from "../components/repeating_elements/Typography.js";

import GDPR from '../components/Global/GDPR';

class SEO_Landing extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
		}
	}
	componentDidMount() {

	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		if(data.acf){
			return (
				<ParallaxProvider><PageTransition>
					<GDPR data={this.state.cookieBanner} />
					<div className="site-contain">
						<Navigation links={this.state.headerLinks} />
						  <div className="page-main">
							<WorkWithUs />
							<div className={"page longform-page " + page_ready}>
								<PanoramicHero slides={data} />
								<MissionStatement data={data[0]} />
								<PageBreak />
								<Accordion data={data[0].acf} />
								<PageBreak />
								<Clients data={data[0].acf} />
								<PageBreak />
								<Contact data={data[0].acf} />
								<div className="page-break do-not-show grid-m-6 grid-t-16 grid-d-16 default-animation invisible"></div>
								<Footer links={this.state.footerLinks} />
							</div>
						</div>
					</div>
				</PageTransition></ParallaxProvider>
			);
		} else{
			return (
				<div></div>
			);
		}
	}
}

export default SEO_Landing;
