import React from "react";

const LargeTitle = props => {
	const outside_classes = props.className ? " " + props.className : "";
	return <h1 className={"title-container styling-large-title" + outside_classes}>
		<span className="title">{props.title}</span>
	</h1>;
}

const HeaderOne = props => {
	const outside_classes = props.className ? " " + props.className : "";
	return <h1 className={"title-container styling-header-1" + outside_classes}>
		<span className="title">{props.title}</span>
	</h1>;
}

const HeaderTwo = props => {
	const outside_classes = props.className ? " " + props.className : "";
	return <h2 className={"title-container styling-header-2" + outside_classes}>
		<span className="title">{props.title}</span>
	</h2>;
}

const BodyCopy = props => {
	const outside_classes = props.className ? " " + props.className : "";
	return <div className={"title-container styling-body" + outside_classes}>
		{props.children}
	</div>;
}

const HeaderEyebrow = props => {
	const outside_classes = props.className ? " " + props.className : "";
	return <h5 className={"title-container styling-eyebrow" + outside_classes}>
		<span className="title">{props.title}</span>
	</h5>;
}

export {LargeTitle, HeaderOne, HeaderTwo, BodyCopy, HeaderEyebrow}
