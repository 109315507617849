import React, { Component } from "react";

// import Slider from "react-slick";

import { TimelineMax, TweenMax, Power1 } from 'gsap'
import 'intersection-observer';
import { InView } from 'react-intersection-observer'

// import Arrow_Head from "../../assets/svg/arrow-head.svg";
import { split } from "../utilities/split";


export default class Statistics extends Component {
	render() {
		return (
			<div className="row csc-statistics-display">
				<div className="grid-m-3 grid-t-4 grid-d-4 percentage">
					<div className="number">{this.props.data.left_stat}{this.props.data.left_stat_unit}</div>
					<p className="tagline">{this.props.data.left_stat_description}</p>
				</div>
				<div className="grid-m-3 grid-t-4 grid-d-4 percentage">
					<div className="number">{this.props.data.right_stat}{this.props.data.right_stat_unit}</div>
					<p className="tagline">{this.props.data.right_stat_description}</p>
				</div>
				<div className="grid-m-6 grid-t-8 grid-d-8 info-text">
					<h3>{this.props.data.title}</h3>
					<p>{this.props.data.description}</p>
				</div>
			</div>
		);
	}
}
