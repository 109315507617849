import React, { Component } from "react";
import { Link } from 'gatsby';

import 'intersection-observer'

import { InView } from 'react-intersection-observer';
import { HeaderTwo, BodyCopy, HeaderEyebrow } from "../repeating_elements/Typography.js";

export default function Contact(props) {
	let data = this.props.data;
	return (
		<div className="grid contact-section">
			<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1">
				<div className="grid-reset">
					<div className="grid-d-16">
						<HeaderEyebrow title={data["contact_title"]} />
						<p className="styling-header-1" style={{marginBottom: "0.67em"}}>Want to <Link to="/contact/new-business" title="Start a project">create</Link><br/> something beautiful?</p>
						<BodyCopy>{data["contact_copy"]}</BodyCopy>
						<Link className="button" url="/contact/new-business">Work with us</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
