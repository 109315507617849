export function split(element) {
    const string = element.innerText
    const outer = 'display: inline-block; overflow: hidden; margin-top: -15%'
    const inner = 'display: inline-block; line-height: 115%;'
    let output = ''
    const array = string.split('')
    for (let el of array) {
        if (el === ' ') el = '&nbsp;'
        output += `<div style="${outer}"><div style="${inner}">${el}</div></div>`
    }
    element.innerHTML = output
    const list = element.querySelectorAll('div > div')
    for (const findSpace of Array.from(list)) {
        if (findSpace.offsetLeft - findSpace.parentNode.parentNode.offsetLeft === 0 && findSpace.innerHTML === '&nbsp;') findSpace.innerHTML = ''
    }
    return list
}

export default { split }