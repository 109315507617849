import React, { Component } from "react";
import { Link } from 'gatsby';

import 'intersection-observer'

import { InView } from 'react-intersection-observer';
import { HeaderTwo, BodyCopy, HeaderEyebrow } from "../repeating_elements/Typography.js";
import { ArrowLink } from "../buttons/Arrow_Link.js";
import Statistics from "./Statistics.js";

export default class Clients extends Component {
	constructor(props){
		super(props);
		this.isLoaded = false;
		this.state = {
			in_view: false,
			position: null,
			activeAccordion: false,
			activeHeight: "auto"
		};
		this.render_sections = this.render_sections.bind(this);
		this.render_services = this.render_services.bind(this);
		this.updateActive = this.updateActive.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.render_slide = this.render_slide.bind(this);
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		this.handleScroll()
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
	handleScroll() {
		if (this.parent.getBoundingClientRect().top < window.innerHeight && !this.isLoaded) {
			this.isLoaded = true
			const images = this.parent.querySelectorAll('img[data-src]')
			for (const image of images) {
				image.src = image.dataset.src
			}
		}
	}
	handleInView(inView) {
		if (inView) {
			this.title.classList.remove("invisible");
		}
	}
	updateActive(slide){
		const name = this.state.activeAccordion === slide.id ? false : slide.id;
		this.setState({
			activeAccordion: slide.id,
			activeHeight: slide.contentElement.current.clientHeight
		});
	}
	render_services(service_list){
		if(service_list.length){
			return service_list.map((service) =>
				<li>{ service.name }</li>
			);
		}
	}
	render_slide(slide){
		let extraClasses = "";
		let sectionStyle = {
			height: 0,
		};
		slide.contentElement = React.createRef();

		if(!slide.id){
			slide.id = '_' + Math.random().toString(36).substr(2, 9);
		}

		if( slide.id === this.state.activeAccordion ){
			extraClasses = " open";
			sectionStyle = {
				height: this.state.activeHeight + "px",
			};
		}
		if(slide.link_cta){
			return(
				<div key={ slide.name } className={"grid-m-6 grid-t-16 grid-d-16 accordion-slide" + extraClasses}>
					<div className="controller">
						<HeaderTwo title={ slide.name } />
						<div className="icon"></div>
						<button className="controller-button" onClick={ ()=>{ this.updateActive(slide) } }></button>
					</div>
					<div className="content-container" style={sectionStyle}>
						<div className="content" ref={slide.contentElement}>
							<div className="left-side">
								<img src={ slide.desktop_image } loading="lazy"/>
								<BodyCopy>{ slide.copy }</BodyCopy>
								<Link className="button" to={ slide.link_url }>{ slide.link_cta }</Link>
							</div>
							<div className="right-side">
								<HeaderEyebrow title="Services" />
								<ul className="service-listing">
									{ this.render_services(slide.services) }
								</ul>
							</div>
							<Statistics key={"module-746"} data={slide.csm_statistics_display} />
						</div>
					</div>
					<div className="seperator"></div>
				</div>
			)
		} else{
			return(
				<div key={ slide.name } className={"grid-m-6 grid-t-16 grid-d-16 accordion-slide" + extraClasses}>
					<div className="controller">
						<HeaderTwo title={ slide.name } />
						<div className="icon"></div>
						<button className="controller-button" onClick={ ()=>{ this.updateActive(slide) } }></button>
					</div>
					<div className="content-container" style={sectionStyle}>
						<div className="content" ref={slide.contentElement}>
							<div className="left-side">
								<img src={ slide.desktop_image } loading="lazy"/>
								<BodyCopy>{ slide.copy }</BodyCopy>
							</div>
							<div className="right-side">
								<HeaderEyebrow title="Services" />
								<ul className="service-listing">
									{ this.render_services(slide.services) }
								</ul>
							</div>
							<Statistics key={"module-746"} data={slide.csm_statistics_display} />
						</div>
					</div>
					<div className="seperator"></div>
				</div>
			)
		}
	}
	render_sections(){
		if(this.props.data.accordion_slides){
			return this.props.data.accordion_slides.map((slide) => {
				return ( this.render_slide(slide) )
			});
		}
	}
	render() {
		return(
			<InView as="div" triggerOnce={true} rootMargin="0% 0% -10%" className="grid clients-feature" onChange={inView => this.handleInView(inView)}>
				<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1" ref={c => this.parent = c}>
					<div className="grid-reset">
						<h2 className="grid-t-16 grid-d-16 styling-eyebrow default-animation invisible" ref={c => this.title = c}>{ this.props.data.accordion_title }</h2>
						<div className="row accordion-wrap">
							{this.render_sections()}
						</div>
					</div>
				</div>
			</InView>
		);
	}
}
