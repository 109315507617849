import React, { Component } from "react";
import { Link } from 'gatsby';

import { InView } from 'react-intersection-observer';

import { ArrowLink } from "../buttons/Arrow_Link.js";
// import { split } from "../utilities/split.js";

export default class MissionStatement extends Component {
	handleInView(inView) {
		if (inView) {
			this.eyebrow.classList.remove("invisible");
			this.title.classList.remove("invisible");
			this.arrow.classList.remove("invisible");
		}
	}
	render() {
		let data = this.props.data.acf;
		return(
			<div className="mission-statement grid">
				<div id="start-content"></div>
				<div className="interior-mission-statement grid-m-6 grid-t-16 push-d-1 grid-d-14">
					<h1 className="styling-eyebrow default-animation" ref={c => this.eyebrow = c}>{ data["mission-statement-title"] }</h1>
					<InView as="div" triggerOnce={true} rootMargin="0% 0% -20%" onChange={inView => this.handleInView(inView)} className="content-contain">
						<h3 className="styling-header-1 default-animation" ref={c=> this.title = c}>{ data["mission-statement-copy"] }</h3>
						<div className="default-animation" ref={c=> this.arrow = c}>
							<p>{data["mission-statement-cta"]}</p>
						</div>
					</InView>
				</div>
			</div>
		);
	}
}
